export {};

declare global {
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var logout_link_override_section: {
    base_url: string;
  };
}

/**
 * Update logout links to include the return url. This way when an authenticated user clicks on the
 * logout link, they are redirected back to the same page instead of the default home page.
 *
 * When a visitor is not authenticated, the logout links will not be found, in which case this does
 * nothing.
 *
 * SECURITY: Hackers can abuse redirect parameters to redirect users to malicious websites. Please
 * ensure that the return url is sane.
 *
 * @todo at checkout shopify presents a link to the cart. if authenticated, there is a url parameter
 * included in the link "logged_in=true". right now we erroneously append that url parameter to the
 * return to url on the current page (e.g. on the cart page). we need to remove that parameter.
 *
 * @todo this should be rewritten to account for shopify routes (e.g. /en-ca/account)
 *
 * @todo if this script is written to be loaded via shopify defer, then i do not think that we need
 * to care about waiting for the dom content loaded event, please investigate, see
 * https://shopify.dev/docs/storefronts/themes/architecture/sections/section-assets#javascript
 */
function onDOMContentLoaded(_event: Event) {
  // The homepage is the default return url. In this case we can just do nothing.
  if (location.pathname === '/') {
    return;
  }

  // Avoid setting the return url to a page that only authenticated users can see.
  if (location.pathname.includes('/customer_identity')) {
    return;
  }

  const returnUrl = location.pathname + location.search;

  // The logout redirect url parameter is named "return_url", not "return_to". The "return_to" is
  // used by the login form.

  // The return url must be absolute.

  const selector = 'a[href^="/customer_identity/logout"]';
  const anchors = document.querySelectorAll<HTMLAnchorElement>(selector);
  for (const anchor of anchors) {
    const href = anchor.getAttribute('href');
    if (href) {
      try {
        const url = new URL(href, logout_link_override_section.base_url);
        url.searchParams.set('return_url', returnUrl);
        anchor.href = url.href;
      } catch (error) {}
    }
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
